<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{
            $t("components.operations.preOpening.stageModalDetails.preOpening")
          }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>
    <template v-slot:content>
      <ModalSideContent>
        <div class="flex justify-between">
          <div class="text-sm text-neutral-400">
            Stage {{ stage.details.sortIndex }}
          </div>
          <DueDateBadge
            :due-date="stage.dueDate"
            :due-date-passed="stage.dueDatePassed"
          />
        </div>
        <div class="mb-4 text-lg font-medium text-neutral-900">
          {{ stage.details.name }}
        </div>
        <div class="text-sm text-neutral-600">
          {{ stage.details.description }}
        </div>
      </ModalSideContent>

      <ModalSideContent>
        <div class="mb-6 flex items-center justify-between">
          <div class="flex">
            <IconPackage class="mr-2 text-neutral-300" />
            <div class="text-xs font-medium uppercase text-neutral-900">
              {{
                $t(
                  "components.operations.preOpening.stageModalDetails.availableResources",
                )
              }}
            </div>
          </div>
          <BaseButton
            :disabled="!assetPackageAvailable"
            size="large"
            :text="
              $t(
                `components.operations.preOpening.stageModalDetails.downloadResources`,
              )
            "
            @buttonClick="downloadAssetPackage(stage.details.assetPackage)"
          >
            <template #iconLeft> <IconDownload class="white" /> </template
          ></BaseButton>
        </div>
        <div>
          <AssetsRow
            v-for="asset in stage.details.assetPackage.assets"
            :key="asset.id"
            :asset="asset"
            class="border-t border-neutral-50 first:border-transparent"
          ></AssetsRow>
        </div>
      </ModalSideContent>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";

import DueDateBadge from "@/components/operations/preOpening/DueDateBadge";
import AssetsRow from "@/components/operations/preOpening/AssetsRow";

import { mapActions } from "vuex";

export default {
  components: {
    ModalSide,
    DueDateBadge,
    AssetsRow,
    ModalSideContent,
  },

  props: {
    stage: {
      type: Object,
      required: true,
    },
  },

  computed: {
    assetPackageAvailable: function () {
      return !!this.stage.details.assetPackage.file;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },

    ...mapActions("assetPackage", ["downloadAssetPackage"]),
  },
};
</script>
