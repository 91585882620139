<template>
  <div
    class="shadow transition-bg rounded-sm bg-white p-5 pb-4 transition-colors duration-200 hover:bg-neutral-0"
    @click="emitClick"
  >
    <div class="mb-1 flex items-center justify-between">
      <div class="text-xs-relaxed font-medium uppercase text-primary-300">
        {{ translations.stage }} {{ stageDetails.sortIndex }}
      </div>
      <slot name="dateBadge"></slot>
    </div>
    <div class="mb-6 font-medium text-neutral-900">
      {{ stageDetails.name }}
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <IconPackage class="mr-2 w-5 text-neutral-300" />
        <span class="text-xs text-neutral-400">
          {{ stageDetails.assetPackage.assetCount }}
          {{ translations.availableResources }}
        </span>
      </div>

      <OverflowMenu :items="menuItems" />
    </div>
  </div>
</template>

<script>
import OverflowMenu from "@/components/common/OverflowMenu";

export default {
  components: {
    OverflowMenu,
  },

  props: {
    stageDetails: {
      type: Object,
      required: true,
    },
    menuItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      translations: {
        stage: this.$t("components.operations.preOpening.stageCard.stage"),
        availableResources: this.$t(
          "components.operations.preOpening.stageCard.availableResources",
        ),
      },
    };
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
