<template>
  <div class="sm:container">
    <div class="mb-8 grid gap-8 px-5 lg:mb-12 lg:grid-cols-3 lg:px-0">
      <div class="col-span-2">
        <div class="flex">
          <router-link
            :to="{
              name: `pre-opening-overview`,
            }"
            class="mr-3 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-sm transition-all duration-200 hover:bg-neutral-50"
          >
            <IconChevronLeft class="h-6 w-6" />
          </router-link>
          <div>
            <h2 class="text-lg font-medium text-neutral-900">
              {{ translations.title }} {{ currentPreOpening.store.name }}
            </h2>
            <span class="text-xs text-neutral-400">
              {{ translations.description }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1">
        <ProgressBar
          :stages="currentPreOpening.stages"
          :done-stages="done"
          :current-stage="currentStage"
        />
      </div>
    </div>

    <div v-if="error">
      {{ error }}
    </div>

    <div class="grid gap-8 lg:grid-cols-3">
      <div class="flex flex-col bg-neutral-50-alpha-50 px-6 pt-4 pb-6">
        <h3 class="mb-4 text-xs font-bold uppercase text-neutral-600">
          {{ translations.todo }}
        </h3>
        <draggable
          v-model="todoList"
          group="cards"
          :animation="300"
          class="flex-1 select-none"
          :force-fallback="true"
        >
          <StageCard
            v-for="stage in todoList"
            :key="stage.details.uuid"
            class="mb-2 cursor-move"
            :stage-details="stage.details"
            :menu-items="stageCardMenuItems"
            @click="openModalDetails(stage)"
          >
            <template v-slot:dateBadge>
              <DueDateBadge
                :due-date="stage.dueDate"
                :due-date-passed="stage.dueDatePassed"
              />
            </template>
          </StageCard>
        </draggable>
      </div>
      <div class="flex flex-col bg-neutral-50-alpha-50 px-6 pt-4 pb-6">
        <h3 class="mb-4 text-xs font-bold uppercase text-neutral-600">
          {{ translations.inProgress }}
        </h3>
        <draggable
          v-model="inProgressList"
          group="cards"
          :animation="300"
          class="flex-1 select-none"
          :force-fallback="true"
        >
          <StageCard
            v-for="stage in inProgressList"
            :key="stage.details.uuid"
            class="mb-2 cursor-move"
            :stage-details="stage.details"
            :menu-items="stageCardMenuItems"
            @click="openModalDetails(stage)"
          >
            <template v-slot:dateBadge>
              <DueDateBadge
                :due-date="stage.dueDate"
                :due-date-passed="stage.dueDatePassed"
              />
            </template>
          </StageCard>
        </draggable>
      </div>
      <div class="flex flex-col bg-neutral-50-alpha-50 px-6 pt-4 pb-6">
        <h3 class="mb-4 text-xs font-bold uppercase text-neutral-600">
          {{ translations.done }}
        </h3>
        <draggable
          v-model="doneList"
          group="cards"
          :animation="300"
          class="flex-1 select-none"
          :force-fallback="true"
        >
          <StageCard
            v-for="stage in doneList"
            :key="stage.details.uuid"
            class="mb-2 cursor-move"
            :stage-details="stage.details"
            :menu-items="stageCardMenuItems"
            @click="openModalDetails(stage)"
          ></StageCard>
        </draggable>
      </div>
    </div>
    <transition name="fade">
      <StageModalDetails
        v-if="modalDetailsOpen"
        :stage="selectedStage"
        @close="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import StageCard from "@/components/operations/preOpening/StageCard";
import StageModalDetails from "@/components/operations/preOpening/StageModalDetails";
import ProgressBar from "@/components/operations/preOpening/ProgressBar";
import DueDateBadge from "@/components/operations/preOpening/DueDateBadge";

import store from "@/store";
import Draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PreOpeningBoard",

  components: {
    Draggable,
    StageCard,
    StageModalDetails,
    ProgressBar,
    DueDateBadge,
  },

  data() {
    return {
      modalDetailsOpen: false,
      selectedStage: null,

      stageCardMenuItems: [
        {
          name: this.$t(
            "components.operations.preOpening.stageCard.viewDetails",
          ),
          showIf: true,
          callback: () => {}, // Empty callback because the @click on the card already triggers the details modal
        },
      ],

      translations: {
        title: this.$t("components.operations.toolbarNavigation.preOpening"),
        description: this.$t(
          "components.operations.preOpening.preOpeningBoardDescription",
        ),
        todo: this.$t("components.operations.preOpening.todo"),
        inProgress: this.$t("components.operations.preOpening.inProgress"),
        done: this.$t("components.operations.preOpening.done"),
      },
    };
  },

  beforeRouteEnter(to, _from, next) {
    store
      .dispatch(
        "singlePreOpening/fetchSinglePreOpening",
        to.params.preOpeningUuid,
      )
      .then(() => next());
  },

  computed: {
    ...mapGetters("singlePreOpening", [
      "todo",
      "inProgress",
      "done",
      "error",
      "currentPreOpening",
      "currentStage",
    ]),

    todoList: {
      get() {
        return this.todo;
      },
      set(items) {
        this.updateStatus({ items: items, status: "todo" });
      },
    },
    inProgressList: {
      get() {
        return this.inProgress;
      },
      set(items) {
        this.updateStatus({ items: items, status: "inProgress" });
      },
    },
    doneList: {
      get() {
        return this.done;
      },
      set(items) {
        this.updateStatus({ items: items, status: "done" });
      },
    },
  },

  methods: {
    openModalDetails(stage) {
      this.selectedStage = stage;
      this.modalDetailsOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.modalDetailsOpen = false;
      this.selectedStage = null;
      document.body.classList.remove("overflow-y-hidden");
    },

    ...mapActions("singlePreOpening", ["updateStatus"]),
  },
};
</script>
