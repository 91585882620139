<template>
  <div class="flex items-center justify-between py-3">
    <div class="flex items-center text-xs text-neutral-600">
      <AssetsPreview
        class="mr-2"
        :asset-filetype-name="asset.file.type"
        :asset-url="asset.file.url"
      ></AssetsPreview>
      {{ asset.name }}
    </div>
    <div class="text-2xs text-neutral-400">
      {{
        $t("components.operations.preOpening.assetsRow.updatedAt", {
          date: updatedAtDate,
        })
      }}
    </div>
  </div>
</template>

<script>
import AssetsPreview from "@/components/brand-marketing/assets/AssetsPreview";

export default {
  components: {
    AssetsPreview,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },

  computed: {
    updatedAtDate() {
      const date = new Date(parseInt(this.asset.updatedAt));
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>
