<template>
  <span
    :class="[
      'rounded-full py-1 px-3 text-xs font-medium',
      { 'bg-danger-50 text-danger-600': dueDatePassed },
      { 'bg-success-100 text-success-600': !dueDatePassed },
    ]"
  >
    {{ $t("components.operations.preOpening.due") }}
    {{ formattedDate }}
  </span>
</template>

<script>
export default {
  props: {
    dueDate: {
      type: String,
      required: true,
    },
    dueDatePassed: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      const date = new Date(this.dueDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>
